class PostMedia {
  constructor(data) {
    Object.keys(data).forEach((key) => {
      this[key] = data[key]
    })
  }

  getUrl() {
    if (this.url && this.url !== '') {
      return this.url
    }
    return this.fallbackUrl
  }

  get isValid() {
    return Boolean((this.url && this.url !== '') || (this.fallbackUrl && this.fallbackUrl !== ''))
  }

  getThumbnailUrl() {
    if (this.isValid && this.isVideo) {
      const url = this.getUrl()
      return url.replace('/0', '/thumbnail')
    }
    return this.getUrl()
  }

  get isImage() {
    return this.type === 'image'
  }

  get isVideo() {
    return this.type === 'video'
  }
}

export default PostMedia
