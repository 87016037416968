import { useState } from 'react'
import { instanceOf, string, object } from 'prop-types'

import MediaUploaderIframe from './MediaUploaderIframe/MediaUploaderIframe.js'
import Locale from '../../../models/Locale.js'
import Icon from '../icon/index.js'

const TYPE_ALIGNS = {
  right: 'flex-end',
  left: 'flex-start',
  center: 'center',
}

const customButtonStyle = (settings) => {
  const { button_color: buttonColor, text_color: textColor, rounding } = settings
  return {
    backgroundColor: buttonColor,
    borderRadius: `${rounding}px`,
    color: textColor,
  }
}

const MediaUploader = ({ feedKey, locale, mediaUploaderSettings }) => {
  const {
    align_button: alignButton,
    text_color: textColor,
    terms_and_conditions_url: termsAndConditionsUrl,
  } = mediaUploaderSettings
  const [enableIframe, setEnableIframe] = useState(false)

  return (
    <div data-testid="media-uploader" style={{ alignSelf: TYPE_ALIGNS[alignButton] }}>
      <button
        className="bzfy-o-media-uploader-button"
        onClick={() => setEnableIframe(true)}
        style={customButtonStyle(mediaUploaderSettings)}
        type="button"
      >
        <Icon color={textColor} icon="submit" />
        <span>{locale.getTranslation('uploadYourMedia')}</span>
      </button>
      {enableIframe && (
        <MediaUploaderIframe
          feedKey={feedKey}
          handleCloseClick={() => setEnableIframe(false)}
          language={locale.language}
          termsAndConditionsURL={termsAndConditionsUrl}
        />
      )}
    </div>
  )
}

MediaUploader.propTypes = {
  feedKey: string.isRequired,
  locale: instanceOf(Locale).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  mediaUploaderSettings: object.isRequired,
}
export default MediaUploader
