// locale is used for catalogs
// language is used for translations
import getLanguage from '../common/utilities/getLanguage.js'
import loadLanguage from '../common/utilities/loadLanguage.js'
import convertToCorrectLocale from '../common/utilities/convertToCorrectLocale.js'

const VALID_LOCALE_REGEX = /^[a-z]{2}-[a-z]{2}$/
const VALID_LANGUAGE_REGEX = /^[a-z]{2}$/
const DEFAULT_LOCALE = 'en-gb'

class Locale {
  constructor(locale) {
    let parsedLocale
    if (locale) {
      const lowercaseLocale = locale.toLowerCase()
      if (lowercaseLocale.match(VALID_LOCALE_REGEX)) {
        parsedLocale = lowercaseLocale
      } else if (lowercaseLocale.match(VALID_LANGUAGE_REGEX)) {
        parsedLocale = convertToCorrectLocale(lowercaseLocale)
      } else if (lowercaseLocale === 'auto') {
        parsedLocale = window.navigator.language
      }
    }
    parsedLocale = parsedLocale ? parsedLocale.toLowerCase() : DEFAULT_LOCALE

    this.locale = parsedLocale
    this.language = getLanguage(parsedLocale)
    this.translations = {
      postAltText: () => {}, // this is a noop needed for Setup and Styling due to promise not resolved from loadLanguage
    }

    this.loadLanguage()
  }

  loadLanguage() {
    return loadLanguage(this.language).then((module) => {
      this.translations = module.default
    })
  }

  get fullLocale() {
    return this.locale && this.locale.match(VALID_LOCALE_REGEX) ? this.locale : ''
  }

  getTranslation(key) {
    return this.translations[key]
  }

  setLocaleBasedOnProductlist(locale) {
    if (locale && this.locale === undefined) {
      this.language = getLanguage(locale)
      this.locale = locale
      this.loadLanguage()
    }
  }

  get isRTL() {
    return ['ar'].includes(this.language)
  }

  toString() {
    return `New Locale: ${this.locale}. Language: ${this.language} `
  }
}

export default Locale
