import { string } from 'prop-types'

export default function IconSocialTikTok({ height, width }) {
  return (
    <svg
      fill="none"
      height={height}
      viewBox="0 0 24 24"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.033 5.11015C17.7937 4.30247 16.9001 3.00959 16.6209 1.50326C16.5609 1.17788 16.5274 0.842689 16.5274 0.5H12.5732L12.5669 16.3468C12.5005 18.1214 11.0398 19.5458 9.24959 19.5458C8.69287 19.5458 8.16902 19.4068 7.70749 19.1639C6.64942 18.6072 5.92539 17.4983 5.92539 16.2222C5.92539 14.3893 7.41672 12.898 9.24901 12.898C9.59113 12.898 9.91939 12.9545 10.2298 13.0514V9.01474C9.90843 8.97089 9.5819 8.94378 9.24901 8.94378C5.23597 8.94378 1.97119 12.2086 1.97119 16.2222C1.97119 18.6845 3.20118 20.8635 5.07847 22.1812C6.26058 23.0113 7.69884 23.5 9.24959 23.5C13.2626 23.5 16.5274 20.2352 16.5274 16.2222V8.18628C18.0782 9.29916 19.9785 9.95511 22.0289 9.95511V6.00092C20.9247 6.00092 19.896 5.67265 19.033 5.11015Z"
        fill="black"
      />
    </svg>
  )
}

IconSocialTikTok.propTypes = {
  height: string,
  width: string,
}

IconSocialTikTok.defaultProps = {
  height: '48px',
  width: '48px',
}
