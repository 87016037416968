const addSingleQueryParam = (url, param, value) => {
  const u = new URL(url, `${window.location}`)
  const params = u.searchParams

  params.append(param, value)

  u.search = `?${params}`
  return `${u}`
}

const addQueryParams = (url, params) => {
  let urlWithParams = url
  Object.keys(params).forEach((param) => {
    urlWithParams = addSingleQueryParam(urlWithParams, param, params[param])
  })
  return urlWithParams
}

export { addSingleQueryParam, addQueryParams }
