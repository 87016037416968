export default function detectWebp(container) {
  if (container.classList.contains('flbx-webp')) {
    return Promise.resolve(false)
  }
  return new Promise((res) => {
    const webP = new Image()
    webP.src =
      'data:image/webp;base64,UklGRjoAAABXRUJQVlA4IC4AAACyAgCdASoCAAIALmk0mk0iIiIiIgBoSygABc6WWgAA/veff/0PP8bA//LwYAAA'
    webP.onload = () => res(webP.height === 2)

    webP.onerror = () => res(webP.height === 2)
  })
}
let hasSupport

export function supportsWebp() {
  if (hasSupport !== undefined) {
    return hasSupport
  }
  hasSupport = !!document.querySelector('.flbx-webp')
  return hasSupport
}
