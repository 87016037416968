import { validateColor } from '../common/utilities/validateValues.js'

class Elements {
  constructor(data) {
    const elements = data.elements || {}
    this.elements = elements

    const { theme } = data
    this.theme = theme

    this.autoplayVideos = elements.autoplayVideos?.value ?? false
    this.defaultBackgroundColor =
      theme === 'tile-grid' || theme === 'tile-grid-auto' ? '#000000' : '#f2f2f2'
    this.backgroundColor = validateColor(
      elements.backgroundColor?.value,
      this.defaultBackgroundColor,
    )
    this.defaultColor = theme === 'tile-grid' || theme === 'tile-grid-auto' ? '#ffffff' : '#000000'
    this.hasArrowBackground = elements.carouselPostArrowBackground?.value ?? false
    this.color = validateColor(elements.color?.value, this.defaultColor)
    this.displayName = elements.contributorDetails?.children?.displayName?.value ?? true
    this.hashtagColor = validateColor(elements.hashtagColor?.value, undefined)
    this.postAge = elements.contributorDetails?.children?.postAge?.value ?? true
    this.postHeaderCentered = elements.headerCentered?.value ?? false
    this.postInteractivity = elements.postInteractivity?.value ?? true
    this.showPlatformIcon = elements.contributorDetails?.children?.platformIcon?.value ?? true
    this.showProfileImage = elements.contributorDetails?.children?.profileImage?.value ?? true
    this.shouldShowPostText = elements.postText?.value ?? true
    this.shouldShowPostIndicator = elements.postIndicator?.value ?? true
    this.largeFontSize = (elements.fontSize?.value ?? 10) * 2.4
    this.feedFontSize = (elements.fontSize?.value ?? 10) * 1.6
    this.smallFontSize = (elements.fontSize?.value ?? 10) * 1.4
    this.shouldShowPostTextGate = elements.postTextGate?.value ?? true
    this.socialCommerceButtonBackgroundColor = validateColor(
      elements.socialCommerceButtonBackgroundColor?.value,
      '#ffffff',
    )
    this.socialCommerceButtonColor = validateColor(
      elements.socialCommerceButtonColor?.value,
      '#000000',
    )
    this.socialCommerceButtonBorderRadius = elements.socialCommerceButtonRounded?.value ?? 4
    this.socialCommerceButtonLarge = elements.socialCommerceButtonLarge?.value ?? false
    this.socialCommerceButtonPosition = elements.socialCommerceButtonPosition?.value ?? 'top'
    this.gateCarouselPostIndicator = elements.gateCarouselPostIndicator?.value ?? false
    this.carouselPostArrowBackground = elements.carouselPostArrowBackground?.value ?? false
  }
}

export default Elements
