export default function buildPublicFeedQuery(
  baseUrl,
  feedKey,
  cursor,
  refresh,
  limit,
  productId,
  tags,
  operator,
  locale,
  source,
) {
  const url = new URL(`${baseUrl}/feed/${feedKey}`)
  const params = url.searchParams

  if (cursor && !refresh) {
    params.append('cursor', JSON.stringify(cursor))
  }

  if (limit) {
    // Potentially we can make each feed load one row of posts at a time by setting limit to an amount base on some setting
    params.append('postsPerPage', limit)
  }

  if (productId) {
    params.append('productId', source ? JSON.stringify([productId]) : productId)
  } else if (tags) {
    params.append('tags', tags)
    if (operator) {
      params.append('operator', operator)
    }
  }

  if (locale) {
    params.append('locale', locale)
  }
  if (source) {
    params.append('source', source)
  }

  return url.href
}
