import { getCookie } from './cookie.js'
import registerEvent from './webAnalytics.js'

export default function sendTrackingEvent({
  action,
  allowCookies,
  enableWebAnalytics,
  ctaType,
  eventName,
  eventLabel,
  eventAction,
  interactive,
  flowKey,
  postId,
  postPosition,
  productKey,
  target,
  visiblePostIds,
  visibleProductKeys,
} = {}) {
  if (!allowCookies || process.env.NODE_ENV === 'development') {
    return
  }

  const id = getCookie('_flowbox', allowCookies)
  if (!id) {
    return
  }

  if (enableWebAnalytics) {
    registerEvent(eventLabel, eventAction, interactive)
  }

  const event = {
    cookies_disabled: !allowCookies,
    created_at: new Date().toISOString(),
    data: {
      action,
      event_name: eventName,
      flow_key: flowKey,
      target,
    },
    from_url: window.location.href,
    user_id: id,
    version: '1.0.0',
  }

  if (ctaType) {
    event.data.cta_type = ctaType
  }

  if (postId) {
    event.data.post_id = postId
  }

  if (postPosition || postPosition === 0) {
    event.data.post_position = postPosition
  }

  if (productKey) {
    event.data.product_Key = productKey
  }

  if (visiblePostIds) {
    event.data.visible_post_ids = [...visiblePostIds]
  }

  if (visibleProductKeys) {
    event.data.visible_product_keys = visibleProductKeys
  }

  fetch(`${__FLOWBOX_ANALYTICS_ENDPOINT__}`, {
    body: JSON.stringify(event),
    method: 'POST',
    headers: {
      authorization: `FlowKey ${flowKey}`,
      'Content-Type': 'application/json',
    },
  }).catch(() => {
    // eslint-disable-next-line no-console
    console.warn('Cannot send Flowbox analytics request')
  })
}
