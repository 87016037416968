import normalizeColor from './normalizeColor.js'

function validateColor(color, defaultColor) {
  if (color === null || color === undefined) {
    return normalizeColor(defaultColor)
  }
  const value = color.trim()
  return /^#?[0-9a-fA-F]{3,8}$/.test(value) ? normalizeColor(value) : normalizeColor(defaultColor)
}

function validateText(value, defaultValue) {
  if (value === null || value === undefined || value === '') {
    return defaultValue
  }
  return value
}

function validateValue(value, defaultValue) {
  if (value === null || value === undefined) {
    return defaultValue
  }
  return value
}

export { validateColor, validateText, validateValue }
