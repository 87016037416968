/* eslint-disable no-console */
export default function destroyFlow(settings = {}) {
  const { container } = settings
  const element = typeof container === 'string' ? document.querySelector(container) : container
  if (!element) {
    console.warn(`[flowbox]: Container element was not found.`)
    return
  }

  const { reactRoot } = element
  if (!reactRoot) {
    console.warn('[flowbox]: No embed found on container')
  } else {
    reactRoot.unmount()
  }

  delete element.reactRoot
}
