import { validateColor } from '../common/utilities/validateValues.js'

class General {
  constructor(data) {
    const general = data.general || {}
    this.general = general

    const { theme } = data
    this.theme = theme

    this.defaultContainerWidth = theme === 'single' || theme === 'single-auto' ? 1150 : undefined
    this.containerWidth = general.containerWidth?.value ?? this.defaultContainerWidth
    this.hasContainerWidth = !!general.containerWidth?.value
    this.feedBackgroundColor = validateColor(general.backgroundColor?.value, '#ffffff')
    this.isInfiniteScrollEnabled = general.infiniteScroll?.value ?? false
    this.isMinimalistic = general.minimalistic?.value ?? false
    this.defaultGutterSize = theme === 'tile-grid' || theme === 'tile-grid-auto' ? 0 : 24
    this.gutterSize = general.gutterSize?.value ?? this.defaultGutterSize
    this.postCount = general.postCount?.value ?? 100
    this.hasStaticRows = general.staticRows?.value ?? false
    this.staticColumnsEnabled = general.staticColumns?.value ?? false
    this.largeCards = general.largeCards?.value ?? false
    this.sameSizePosts = general.sameSizeCards?.value ?? false
    this.hasDisplayLimit = general.displayLimit?.value ?? false
    this.likesAndComments = general.likesAndComments?.value ?? true
    this.highlightTimeout = general.highlightTimeout?.value ?? this.defaultHighlightTimeout
    this.rawFontFamily = general.fontFamily?.value ?? '_inherit'
    this.shouldLoadWebFont = this.rawFontFamily[0] !== '_'
    this.slidesToShowEnabled = general.slidesToShow?.value ?? false
    this.mobileSlidesToShowEnabled = general.mobileSlidesToShow?.value ?? false
    this.enableWebAnalytics = general.enableWebAnalytics?.value ?? false
  }

  get containerFixedHeight() {
    const { fixedHeight } = this.general

    if (!fixedHeight?.value) {
      return null
    }
    return fixedHeight.children?.height?.value ?? 400
  }

  get postsToShow() {
    const { displayLimit } = this.general
    if (!displayLimit?.value) {
      return null
    }
    return displayLimit.children?.postsToShow?.value ?? 8
  }

  get rowCount() {
    if (!this.hasStaticRows) {
      return null
    }
    return this.general.staticRows?.children?.rowCount?.value ?? 5
  }

  get columnCount() {
    if (!this.staticColumnsEnabled) {
      return null
    }
    return this.general.staticColumns?.children?.columnCount?.value ?? 4
  }

  get defaultHighlightTimeout() {
    switch (this.theme) {
      case 'carousel':
      case 'carousel-auto':
        return 1

      case 'single':
      case 'single-auto':
        return 5

      default:
        return 8
    }
  }

  get fontFamily() {
    if (!this.shouldLoadWebFont) {
      return this.rawFontFamily.substr(1)
    }
    // converts font names like: open-sans => Open Sans
    const fontFamily = this.rawFontFamily
      .split('-')
      .map((s) => s[0].toUpperCase() + s.substr(1))
      .join('+')

    return `${fontFamily}:400,700:latin`
  }

  get slidesToShow() {
    if (!this.slidesToShowEnabled) {
      return null
    }

    return this.general.slidesToShow?.children?.slidesToShow?.value ?? null
  }

  get mobileSlidesToShow() {
    if (!this.mobileSlidesToShowEnabled) {
      return 2
    }

    return this.general.mobileSlidesToShow?.children?.mobileSlidesToShow?.value ?? 2
  }
}

export default General
