import { PureComponent } from 'react'
import cn from 'classnames'
import { string, shape } from 'prop-types'

import IconSocialTwitter from './icon-social-twitter.js'
import IconSocialInstagram from './icon-social-instagram.js'
import IconSocialFacebook from './icon-social-facebook.js'
import IconSocialYoutube from './icon-social-youtube.js'
import IconSocialLinkedin from './icon-social-linkedin.js'
import IconSocialRss from './icon-social-rss.js'
import IconSocialTumblr from './icon-social-tumblr.js'
import IconArrowLeft from './icon-arrow-left.js'
import IconArrowRight from './icon-arrow-right.js'
import IconClose from './icon-close.js'
import IconSocialPinterest from './icon-social-pinterest.js'
import IconSocialTikTok from './icon-social-tiktok.js'
import IconComments from './icon-comments.js'
import IconLikes from './icon-likes.js'
import IconSubmit from './icon-submit.js'

export default class Icon extends PureComponent {
  static propTypes = {
    className: string,
    color: string,
    height: string,
    icon: string.isRequired,
    style: shape(),
    width: string,
  }

  static defaultProps = {
    className: '',
    color: '#000000',
    height: undefined,
    style: {},
    width: undefined,
  }

  render() {
    const { className, color, style, height, width } = this.props
    const constructedClass = cn('bzfy-icon', `bzfy-icon--${this.props.icon}`, className)

    const icon = {
      'social-twitter': <IconSocialTwitter color={color} height={height} width={width} />,
      'social-instagram': <IconSocialInstagram color={color} height={height} width={width} />,
      'social-facebook': <IconSocialFacebook color={color} height={height} width={width} />,
      'social-youtube': <IconSocialYoutube color={color} height={height} width={width} />,
      'social-linkedin': <IconSocialLinkedin color={color} height={height} width={width} />,
      'social-rss': <IconSocialRss color={color} height={height} width={width} />,
      'social-tumblr': <IconSocialTumblr color={color} height={height} width={width} />,
      'social-pinterest': <IconSocialPinterest color={color} height={height} width={width} />,
      'social-tiktok': <IconSocialTikTok height={height} width={width} />,
      'arrow-left': <IconArrowLeft color={color} />,
      'arrow-right': <IconArrowRight color={color} />,
      likes: <IconLikes color={color} />,
      comments: <IconComments color={color} />,
      close: <IconClose color={color} />,
      submit: <IconSubmit color={color} />,
    }[this.props.icon]

    return icon ? (
      <div className={constructedClass} style={style}>
        {icon}
      </div>
    ) : null
  }
}
