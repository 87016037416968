import { string } from 'prop-types'

export default function IconClose({ color }) {
  return (
    <svg height="20" viewBox="0 0 20 20" width="20">
      <g fill="none" fillRule="evenodd">
        <rect height="20" width="20" />
        <path
          d="M11.118 10l5.639-5.688A.647.647 0 0017 3.779a.647.647 0 00-.243-.535.647.647 0 00-.535-.243.647.647 0 00-.535.243L10 8.882 4.312 3.243A.647.647 0 003.779 3a.647.647 0 00-.535.243.647.647 0 00-.243.535c0 .227.081.405.243.534L8.882 10l-5.639 5.688a.647.647 0 00-.243.534c0 .227.081.405.243.535a.468.468 0 00.243.194.918.918 0 00.583 0 .468.468 0 00.244-.194L10 11.118l5.688 5.639a.468.468 0 00.243.194.918.918 0 00.583 0 .468.468 0 00.243-.194.647.647 0 00.243-.535.647.647 0 00-.243-.535L11.118 10z"
          fill={color}
        />
      </g>
    </svg>
  )
}

IconClose.propTypes = {
  color: string.isRequired,
}
