import buildPublicFeedQuery from '../utilities/buildPublicFeedQuery.js'
import validateFeed from './validate-feed.js'
import { POSTS_PER_PAGE } from '../utilities/constants.js'
import Post from '../../models/Post.js'

/**
 * Fetches posts from the feed based on the passed config.
 * @param {Object.<string, *>} config
 * @param {boolean=} [shouldFetchFromStart=false]
 * @returns {Promise.<Array.<*>>}
 */
async function fetchPosts(config, shouldFetchFromStart = false) {
  const url = buildPublicFeedQuery(
    __API_ENDPOINT__,
    config.feedKey,
    config.cursor,
    shouldFetchFromStart, // Fetches posts from the start so as to get new posts, if false uses cursor
    POSTS_PER_PAGE,
    config.productId,
    config.tags,
    config.operator,
    config?.locale?.locale,
    config?.source,
  )

  const response = await fetch(url)
  const {
    settings,
    posts,
    // We use empty array cause in PS are already inside posts field
    pinned = [],
    cursor,
    media_uploader_settings: mediaUploaderSettings,
    msg = '',
  } = await response.json()

  if (validateFeed(settings, msg)) {
    const allPosts = [...pinned.map((p) => new Post(p)), ...posts.map((p) => new Post(p))]
    return { settings, allPosts, cursor, mediaUploaderSettings }
  }
  return undefined
}

export default fetchPosts
