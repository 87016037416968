const COOKIE_EXPIRATION_DAYS = 730

const generateUuid = crypto?.randomUUID
  ? () => crypto.randomUUID()
  : function generateUuid() {
      const s4 = () =>
        Math.floor((1 + Math.random()) * 0x10000)
          .toString(16)
          .substring(1)
      return `${s4() + s4()}-${s4()}-${s4()}-${s4()}-${s4()}${s4()}${s4()}`
    }

function getCookieExpirationDate() {
  const date = new Date()
  date.setDate(date.getDate() + COOKIE_EXPIRATION_DAYS)
  return date
}

function setCookie(name, value, expires, domain) {
  let cookieString = `${name}=${value}; expires=${expires.toUTCString()}; path=/; SameSite=Lax`
  if (domain) {
    cookieString += `; domain=${domain}`
  }
  document.cookie = cookieString
}

function findCookie(cookieName) {
  const cookies = decodeURIComponent(document.cookie)
  const entries = cookies.split('; ').map((chunk) => chunk.split('='))
  return entries.find(([key]) => key === cookieName)?.[1]
}

function getCookie(cookieName, allowCookies) {
  let cookieId = findCookie(cookieName)
  if (cookieId) {
    return cookieId
  }

  const uuid = generateUuid()
  if (!allowCookies) {
    return uuid
  }

  const domainChunks = window.location.hostname.split('.')
  const expires = getCookieExpirationDate()
  let domain = ''
  while (domainChunks.length && !cookieId) {
    const chunk = domainChunks.pop()
    domain = `.${chunk}${domain}`
    setCookie(cookieName, uuid, expires, domain, allowCookies)
    cookieId = findCookie(cookieName)
  }

  return uuid
}

export { getCookie, setCookie }
