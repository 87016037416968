import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client' // eslint-disable-line import/extensions

import { VisiblePostsContextProvider } from './common/contexts/visiblePostsContext.js'
import HotspotsProvider from './common/components/gate/hotspots-provider.js'
import FeedLoader from './common/feed-loader/FeedLoader.js'

import getFlowConfiguration from './common/utilities/getFlowConfiguration.js'
import flowsStore from './flowsStore.js'
import Locale from './models/Locale.js'
import detectWebp from './common/utilities/supportsWebp.js'
import setLocaleOnContainer from './common/utilities/setLocaleOnContainer.js'

const defaultConfiguration = {
  registerFlow: true,
  forceRender: false,
}

const getApiCompatibleTagsOperator = ({ tags, tagsOperator }) => {
  if (!tags || (Array.isArray(tags) && tags.length === 0)) return undefined
  if (!tagsOperator || !['any', 'all'].includes(tagsOperator)) return 'OR'

  return {
    any: 'OR',
    all: 'AND',
  }[tagsOperator]
}

export default function initializeFlow(settings = {}, configuration = defaultConfiguration) {
  const {
    allowCookies,
    container,
    context,
    key,
    lazyLoad = true,
    productId,
    tags,
    source,
    tagsOperator,
    setupAndStyling,
  } = settings
  if (!key)
    throw new TypeError(
      `[Flowbox]: No key provided. Provide one with "window.flowbox('init', { key: 'flow-key' })"`,
    )

  if (configuration.registerFlow) {
    flowsStore.set(settings)
  }

  const element = typeof container === 'string' ? document.querySelector(container) : container
  if (!element) {
    console.warn(`[flowbox]: Container element was not found.`) // eslint-disable-line no-console
    return
  }

  function renderFlow() {
    const locale = new Locale(settings.locale)
    detectWebp(element).then((hasWebP) => {
      if (hasWebP) {
        element.classList.add('flbx-webp')
      }
    })

    setLocaleOnContainer(element, locale)

    const root = element.reactRoot || createRoot(element)
    element.reactRoot = root
    root.render(
      <StrictMode>
        <VisiblePostsContextProvider>
          <HotspotsProvider>
            <FeedLoader
              allowCookies={allowCookies}
              containerElement={element}
              context={context}
              feedKey={key}
              flowConfiguration={getFlowConfiguration(settings)}
              locale={locale}
              operator={getApiCompatibleTagsOperator({ tags, tagsOperator })}
              productId={productId}
              setupAndStyling={setupAndStyling}
              source={source}
              tags={Array.isArray(tags) ? tags.join(',') : tags}
            />
          </HotspotsProvider>
        </VisiblePostsContextProvider>
      </StrictMode>,
    )
  }

  if (!configuration.forceRender && lazyLoad && typeof IntersectionObserver === 'function') {
    const options = {
      root: null, // relative to document viewport
      rootMargin: '0px', // margin around root. Values are similar to css property. Unitless values not allowed
      threshold: 0, // visible amount of item shown in relation to root
    }

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          observer.disconnect()
          renderFlow()
        }
      })
    }, options)
    observer.observe(element)
  } else {
    renderFlow()
  }
}
