import Elements from './Elements.js'
import Banner from './Banner.js'
import Products from './Products.js'
import Ratings from './Ratings.js'
import LoadMoreButton from './LoadMoreButton.js'
import General from './General.js'
import CustomCss from './CustomCss.js'

export default class Settings {
  constructor(data) {
    this.data = data
    this.elements = new Elements(data)
    this.banner = new Banner(data.elements)
    this.locale = data.locale
    this.products = new Products(data.elements)
    this.ratings = new Ratings(data.elements)
    this.loadMoreButton = new LoadMoreButton(data.elements)
    this.general = new General(data)
    this.allowCookies = data.allowCookies
    this.customCss = new CustomCss(data.customCss)

    this.theme = this.data?.theme

    this.autoplayVideos = this.elements.autoplayVideos
    this.gateCarouselPostIndicator = this.elements.gateCarouselPostIndicator
    this.carouselPostArrowBackground = this.elements.carouselPostArrowBackground
    this.displayName = this.elements.displayName
    this.hashtagColor = this.elements.hashtagColor
    this.showPlatformIcon = this.elements.showPlatformIcon
    this.showProfileImage = this.elements.showProfileImage
    this.postAge = this.elements.postAge
    this.postBackgroundColor = this.elements.backgroundColor
    this.postColor = this.elements.color
    this.postHeaderCentered = this.elements.postHeaderCentered
    this.postInteractivity = this.elements.postInteractivity
    this.showPostText = this.elements.shouldShowPostText
    this.showPostIndicator = this.elements.shouldShowPostIndicator
    this.showPostGateText = this.elements.shouldShowPostTextGate
    this.socialCommerceButtonBackgroundColor = this.elements.socialCommerceButtonBackgroundColor
    this.socialCommerceButtonColor = this.elements.socialCommerceButtonColor
    this.socialCommerceButtonBorderRadius = this.elements.socialCommerceButtonBorderRadius
    this.socialCommerceButtonLarge = this.elements.socialCommerceButtonLarge
    this.socialCommerceButtonPosition = this.elements.socialCommerceButtonPosition
    this.largeFontSize = this.elements.largeFontSize
    this.feedFontSize = this.elements.feedFontSize
    this.smallFontSize = this.elements.smallFontSize

    this.containerWidth = this.general.containerWidth
    this.hasContainerWidth = this.general.hasContainerWidth
    this.containerFixedHeight = this.general.containerFixedHeight
    this.postsToShow = this.general.postsToShow
    this.feedBackgroundColor = this.general.feedBackgroundColor
    this.isInfiniteScrollEnabled = this.general.isInfiniteScrollEnabled
    this.isMinimalistic = this.general.isMinimalistic
    this.largeCards = this.general.largeCards
    this.sameSizePosts = this.general.sameSizePosts
    this.postCount = this.general.postCount
    this.rowCount = this.general.rowCount
    this.gutterSize = this.general.gutterSize
    this.hasDisplayLimit = this.general.hasDisplayLimit
    this.hasStaticRows = this.general.hasStaticRows
    this.showLikesAndComments = this.general.likesAndComments
    this.highlightTimeout = this.general.highlightTimeout
    this.rawFontFamily = this.general.rawFontFamily
    this.shouldLoadWebFont = this.general.shouldLoadWebFont
    this.fontFamily = this.general.fontFamily
    this.slidesToShowEnabled = this.general.slidesToShowEnabled
    this.slidesToShow = this.general.slidesToShow
    this.mobileSlidesToShowEnabled = this.general.mobileSlidesToShowEnabled
    this.mobileSlidesToShow = this.general.mobileSlidesToShow
    this.staticColumnsEnabled = this.general.staticColumnsEnabled
    this.columnCount = this.general.columnCount
    this.enableWebAnalytics = this.general.enableWebAnalytics

    this.bannerPosition = this.banner.position
    this.showBottomBanner = this.banner.showBottom
    this.showCardBanner = this.banner.showCard
    this.showTopBanner = this.banner.showTop
    this.bannerBackgroundColor = this.banner.backgroundColor
    this.bannerPadding = this.banner.padding
    this.bannerLogoUrl = this.banner.logoUrl
    this.bannerHideLogoMobile = this.banner.hideLogoMobile
    this.bannerPrimaryText = this.banner.primaryText
    this.bannerPrimaryFontSize = this.banner.primaryFontSize
    this.bannerPrimaryColor = this.banner.primaryColor
    this.bannerSecondaryText = this.banner.secondaryText
    this.bannerSecondaryFontSize = this.banner.secondaryFontSize
    this.bannerSecondaryColor = this.banner.secondaryColor
    this.showBanner = this.banner.shouldShow

    this.isRatingEnabled = this.ratings.isEnabled
    this.ratingThreshold = this.ratings.threshold
    this.showNumberOfReviews = this.ratings.shouldShowReviews

    this.showLoadMoreButton = this.loadMoreButton.shouldShowLoadMoreButton
    this.loadMoreButtonBackgroundColor = this.loadMoreButton.backgroundColor
    this.loadMoreButtonTextColor = this.loadMoreButton.textColor
    this.loadMoreButtonRounding = this.loadMoreButton.rounding

    this.productButtonText = this.products.buttonText
    this.productListTitleText = this.products.listTitleText
    this.showPrice = this.products.showPrice
    this.productAvailabilityBehavior = this.products.availabilityBehavior
    this.hideOutOfStockProducts = this.productAvailabilityBehavior === 'hide-product'
    this.salesBadgeText = this.products.salesBadgeText
    this.showProductStockStatus = this.productAvailabilityBehavior === 'display'
    this.showSalesBadge = this.products.showSalesBadge
    this.showSalesPrice = this.products.showSalesPrice
    this.showProducts = this.products.showProducts
  }

  get isAutoTheme() {
    const { theme } = this

    return (
      theme === 'card-flow-auto' ||
      theme === 'card-flow-auto-centered' ||
      theme === 'carousel-auto' ||
      theme === 'single-auto' ||
      theme === 'tile-grid-auto'
    )
  }

  get isCenteredTheme() {
    const { theme } = this
    return theme === 'card-flow-centered' || theme === 'card-flow-auto-centered'
  }
}
