import { string } from 'prop-types'

export default function IconLikes({ color }) {
  return (
    <svg height="20" viewBox="0 0 20 20" width="20">
      <g fill="none" fillRule="evenodd">
        <rect height="20" width="20" />
        <path
          d="M10 16.875a.433.433 0 01-.137-.02 1.072 1.072 0 01-.136-.058c-.079-.026-.827-.495-2.247-1.406-1.419-.912-2.714-2.331-3.886-4.258-.39-.651-.703-1.465-.938-2.442-.234-.976-.234-1.894 0-2.754.13-.494.332-.93.606-1.308a3.194 3.194 0 011.035-.918c.312-.208.638-.358.976-.45a3.892 3.892 0 011.016-.136c.86 0 1.608.24 2.246.723.638.481 1.126.93 1.465 1.347.339-.416.827-.866 1.465-1.347.638-.482 1.387-.723 2.246-.723.338 0 .677.046 1.016.137.338.09.664.24.976.449.417.234.762.54 1.035.918.274.378.476.814.606 1.309.234.859.234 1.777 0 2.753-.235.977-.547 1.79-.938 2.442-1.172 1.927-2.467 3.346-3.886 4.258-1.42.911-2.168 1.38-2.247 1.406a1.072 1.072 0 01-.136.058.433.433 0 01-.137.02zm-3.71-12.5a2.41 2.41 0 00-.685.098c-.22.065-.449.162-.683.293a1.931 1.931 0 00-.684.605 2.56 2.56 0 00-.37.879c-.183.651-.177 1.38.019 2.188.195.807.449 1.484.761 2.03.912 1.485 1.928 2.644 3.047 3.477 1.12.834 1.888 1.367 2.305 1.602.417-.235 1.185-.768 2.305-1.602 1.12-.833 2.135-1.992 3.047-3.476.312-.547.566-1.224.761-2.031.196-.808.202-1.537.02-2.188a2.56 2.56 0 00-.371-.879 1.931 1.931 0 00-.684-.605 3.375 3.375 0 00-.683-.293 2.41 2.41 0 00-.684-.098c-.912 0-1.667.365-2.266 1.094l-.898 1.093a.634.634 0 01-.547.274.634.634 0 01-.547-.274L8.555 5.47c-.6-.73-1.354-1.094-2.266-1.094z"
          fill={color}
        />
      </g>
    </svg>
  )
}

IconLikes.propTypes = {
  color: string.isRequired,
}
