export const PLATFORMS = {
  TWITTER: 'twitter',
  INSTAGRAM: 'instagram',
  FACEBOOK: 'facebook',
  PINTEREST: 'pinterest',
  TUMBLR: 'tumblr',
  YOUTUBE: 'youtube',
  RSS: 'rss',
  LINKEDIN: 'linkedin',
  TIKTOK: 'tiktok',
  CUSTOM: 'custom',
}

/*
We settled on 29 as a post per page since it's a prime number and therefore only divisible by 1 or itself.
The public feed lambda limits us to get 30 posts and 29 is the closest to that.
We wanted to use a prime number since that would never be divisible by the column count.
*/
export const POSTS_PER_PAGE = 29

export const supportedLanguages = [
  'ar',
  'bg',
  'cs',
  'da',
  'de',
  'el',
  'en',
  'es',
  'et',
  'fi',
  'fr',
  'hr',
  'hu',
  'id',
  'is',
  'it',
  'ja',
  'ko',
  'lt',
  'lv',
  'nb',
  'nl',
  'pl',
  'pt',
  'ro',
  'ru',
  'sk',
  'sl',
  'sv',
  'th',
  'tr',
  'uk',
  'zh',
]

export const GATE_SIZES = `(min-width: 1024px) 1080px, ((min-width: 799px) and (max-width: 1023px)) 750px, 640px`
export const GATE_SRC_SETS = [640, 750, 1080]
