import { createContext, useCallback, useState } from 'react'
import PropTypes from 'prop-types'

const VisiblePostsContext = createContext()

function VisiblePostsContextProvider({ children }) {
  const [visiblePostIds, setVisiblePostIds] = useState(new Set())

  const addVisiblePostId = useCallback(
    (postId) =>
      setVisiblePostIds((prevState) => {
        if (prevState.has(postId)) {
          return prevState
        }
        const newState = new Set(prevState)
        newState.add(postId)
        return newState
      }),
    [],
  )

  const removeVisiblePostId = useCallback(
    (postId) =>
      setVisiblePostIds((prevState) => {
        if (!prevState.has(postId)) {
          return prevState
        }
        const newState = new Set(prevState)
        newState.delete(postId)
        return newState
      }),
    [],
  )

  return (
    <VisiblePostsContext.Provider value={{ visiblePostIds, addVisiblePostId, removeVisiblePostId }}>
      {children}
    </VisiblePostsContext.Provider>
  )
}

VisiblePostsContextProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
}

VisiblePostsContextProvider.defaultProps = {
  children: undefined,
}

export { VisiblePostsContextProvider, VisiblePostsContext }
