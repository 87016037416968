import sendTrackingEvent from '../utilities/sendTrackingEvent.js'

function sendEmbedLoadedEvent(component, enableWebAnalytics) {
  const instance = component
  const { feedKey, allowCookies } = instance.props
  const { visiblePostIds } = instance.context || {}
  instance.isEmbedLoaded = true
  if (visiblePostIds?.size) {
    sendTrackingEvent({
      action: 'view',
      allowCookies,
      enableWebAnalytics,
      eventName: 'embed_viewed',
      eventLabel: 'embed',
      eventAction: 'viewed',
      flowKey: feedKey,
      target: 'flow',
      visiblePostIds,
    })
  }
}

export default sendEmbedLoadedEvent
