import { string } from 'prop-types'

export default function IconComments({ color }) {
  return (
    <svg height="20" viewBox="0 0 20 20" width="20">
      <g fill="none" fillRule="evenodd">
        <rect height="20" width="20" />
        <path
          d="M6.875 17.188a.642.642 0 01-.156-.02.248.248 0 01-.117-.059.68.68 0 01-.254-.214.556.556 0 01-.098-.332v-2.5H4.375c-.52 0-.964-.183-1.328-.547a1.808 1.808 0 01-.547-1.329v-7.5c0-.52.182-.963.547-1.328a1.808 1.808 0 011.328-.546h11.25c.52 0 .964.182 1.328.546.365.365.547.808.547 1.329v7.5c0 .52-.182.963-.547 1.328a1.808 1.808 0 01-1.328.546h-4.18L7.227 17.07a.34.34 0 01-.176.098.905.905 0 01-.176.02zm-2.5-13.125a.608.608 0 00-.45.175.608.608 0 00-.175.45v7.5c0 .182.059.332.176.449a.608.608 0 00.449.175h2.5c.182 0 .332.06.45.176a.608.608 0 01.175.45v1.914l3.398-2.422c.053-.026.111-.052.176-.078a.48.48 0 01.176-.04h4.375a.608.608 0 00.45-.175.608.608 0 00.175-.45v-7.5a.608.608 0 00-.176-.449.608.608 0 00-.449-.176H4.375z"
          fill={color}
        />
      </g>
    </svg>
  )
}

IconComments.propTypes = {
  color: string.isRequired,
}
