import { Component } from 'react'
import { string, bool, shape, instanceOf } from 'prop-types'

import debounce from '../utilities/debounce.js'
import { setupAndStylingStore } from '../../flowsStore.js'
import Settings from '../../models/settings.js'
import Locale from '../../models/Locale.js'
import Feed from '../components/feed/Feed.js'
import fetchPosts from './fetch-posts.js'
import sendEmbedLoadedEvent from './send-embed-loaded-event.js'
import dedupeAndSort from './dedupe-posts-and-sort.js'
import { VisiblePostsContext } from '../contexts/visiblePostsContext.js'

const DEFAULT_STATE = {
  visiblePosts: [],
  preloadedPosts: [],
  cursor: null,
  settings: {},
  shouldFetchMore: true,
}

class FeedLoader extends Component {
  static propTypes = {
    allowCookies: bool,
    containerElement: shape().isRequired,
    context: string,
    feedKey: string.isRequired,
    // eslint-disable-next-line react/no-unused-prop-types
    flowConfiguration: shape().isRequired,
    locale: instanceOf(Locale).isRequired,
    operator: string,
    productId: string,
    source: string,
    tags: string,
  }

  static defaultProps = {
    allowCookies: true,
    context: undefined,
    operator: undefined,
    productId: undefined,
    source: undefined,
    tags: undefined,
  }

  constructor(props) {
    super(props)
    this.state = { ...DEFAULT_STATE }
    this.isEmbedLoaded = false
    this.prevContext = {}
    /**
     * Wait for a little bit before sending the event when this function is called,
     * as the data to be sent might still be in the process of updating.
     */
    this.sendEmbedLoadedEvent = debounce(sendEmbedLoadedEvent, 500)
  }

  async componentDidMount() {
    await this.loadPosts()
    this.prevContext = this.context
  }

  componentDidUpdate(prevProps) {
    const { state, props } = this
    if (
      prevProps.feedKey !== props.feedKey ||
      prevProps.productId !== props.productId ||
      prevProps.tags !== props.tags ||
      prevProps.operator !== props.operator
    ) {
      this.setState({ ...DEFAULT_STATE }, () => this.loadPosts(true))
    }

    if (this.prevContext?.visiblePostIds !== this.context.visiblePostIds) {
      const currentSettings = new Settings(state.settings)
      this.sendEmbedLoadedEvent(this, currentSettings.enableWebAnalytics)
    }
    this.prevContext = this.context
  }

  fetchFirstPosts = async (refresh) => {
    const { feedKey, productId, tags, operator, allowCookies, locale, source } = this.props

    const config = {
      feedKey,
      productId,
      tags,
      operator,
      cursor: this.state.cursor,
      allowCookies,
      locale,
      source,
    }
    return fetchPosts(config, refresh)
  }

  fetchAndSaveNextPosts = async () => {
    const { feedKey, productId, tags, operator, allowCookies, locale, source } = this.props
    const config = {
      feedKey,
      productId,
      tags,
      operator,
      cursor: this.state.cursor,
      allowCookies,
      locale,
      source,
    }
    const nextPostsResponse = await fetchPosts(config)

    if (nextPostsResponse) {
      const { allPosts, cursor } = nextPostsResponse

      this.setState((prevState) => ({
        ...prevState,
        preloadedPosts: allPosts,
        cursor,
        shouldFetchMore: !!cursor || allPosts.length > 0,
      }))
    }
  }

  displayFetchedPosts = (refresh, fetchedPostsResponse) => {
    const { settings, allPosts, cursor, mediaUploaderSettings } = fetchedPostsResponse
    const newSettings = this.isInSetupAndStyling()
      ? setupAndStylingStore(this.props.feedKey)
      : settings

    this.setState(
      (prevState) => ({
        ...prevState,
        visiblePosts: dedupeAndSort(refresh, prevState.visiblePosts, allPosts),
        cursor,
        settings: newSettings,
        mediaUploaderSettings,
        shouldFetchMore: !!cursor,
      }),
      () => cursor && this.fetchAndSaveNextPosts(),
    )
  }

  displayPreloadedPosts = (refresh) => {
    const { preloadedPosts: currentPreloadedPosts, cursor: currentCursor } = this.state

    this.setState(
      (prevState) => ({
        ...prevState,
        visiblePosts: dedupeAndSort(refresh, prevState.visiblePosts, currentPreloadedPosts),
        preloadedPosts: [],
        shouldFetchMore: !!currentCursor,
      }),
      () => currentCursor && this.fetchAndSaveNextPosts(),
    )
  }

  /**
   * Fetches posts from the Backend to display them in the feed.
   * It preloads nexts posts to improve the performance and user experience.
   *
   * If first load: fetch posts, update state to display the first batch and fetch the next batch of posts.
   * If some posts already preloaded: Update state to display the preloaded posts and fetch the next batch of posts.
   *
   * When the BE returns cursor as null, it means that there are no more posts to fetch.
   */
  loadPosts = async (refresh) => {
    const {
      visiblePosts: currentVisiblePosts,
      preloadedPosts: currentPreloadedPosts,
      shouldFetchMore: currentShouldFetchMore,
    } = this.state

    const currentSettings = new Settings(this.state.settings)

    if (currentVisiblePosts.length >= currentSettings.postCount) {
      // We have already fetched the maximum number of posts
      return
    }

    if (!currentShouldFetchMore) {
      return
    }

    if (currentPreloadedPosts.length === 0 && currentVisiblePosts.length === 0) {
      const firstPostsResponse = await this.fetchFirstPosts(refresh)
      if (!firstPostsResponse) {
        return
      }
      this.displayFetchedPosts(refresh, firstPostsResponse)
    }

    if (currentPreloadedPosts.length > 0) {
      this.displayPreloadedPosts(refresh)
    }
  }

  isInSetupAndStyling() {
    return this.props.context === 'setup-and-styling'
  }

  render() {
    const { locale, context, feedKey, allowCookies, containerElement } = this.props
    const { visiblePosts, settings, shouldFetchMore, mediaUploaderSettings } = this.state

    const feedProps = {
      containerElement,
      context,
      feed: {
        posts: visiblePosts,
      },
      feedKey,
      load: ({ refresh = false } = {}) => this.loadPosts(refresh),
      locale,
      shouldFetchMore,
      settings: new Settings({
        ...settings,
        allowCookies,
        locale,
      }),
      mediaUploaderSettings: mediaUploaderSettings || { enable_media_uploader: false },
    }

    return visiblePosts.length ? <Feed feedProps={feedProps} /> : null
  }
}
FeedLoader.contextType = VisiblePostsContext
export default FeedLoader
