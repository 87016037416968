import { Component } from 'react'
import { string, shape, arrayOf, oneOfType, func, instanceOf, object } from 'prop-types'

import Settings from '../../../models/settings.js'
import CustomCss from './theme-components/custom-css.js'
import GateContext from '../../utilities/gateContext.js'

import '../../../styles/default.scss'
import sendTrackingEvent from '../../utilities/sendTrackingEvent.js'
import MediaUploader from '../media-uploader/MediaUploader.js'

function importTheme(theme) {
  switch (theme) {
    case 'gate':
      return import(/* webpackChunkName: "gate" */ '../gate/gate.js')
    case 'card-flow':
    case 'card-flow-auto':
    case 'card-flow-centered':
    case 'card-flow-auto-centered':
      return import(/* webpackChunkName: "cards" */ './themes/card-flow/card-flow.js')
    case 'carousel':
    case 'carousel-auto':
      return import(/* webpackChunkName: "carousel" */ './themes/carousel/carousel.js')
    case 'single':
    case 'single-auto':
      return import(/* webpackChunkName: "single" */ './themes/single/single.js')
    case 'tile-grid':
    case 'tile-grid-auto':
    default:
      return import(/* webpackChunkName: "grid" */ './themes/tile-grid/tile-grid.js')
  }
}

export default class Theme extends Component {
  static propTypes = {
    feedProps: shape({
      containerElement: shape().isRequired,
      feed: shape({
        cursor: oneOfType([shape(), string]),
        pinned: arrayOf(shape()),
        posts: arrayOf(shape()),
      }),
      feedKey: string,
      load: func.isRequired,
      settings: instanceOf(Settings).isRequired,
      // eslint-disable-next-line react/forbid-prop-types
      mediaUploaderSettings: object.isRequired,
    }).isRequired,
  }

  constructor() {
    super()
    this.state = {
      theme: null,
      gate: () => null,
      chosenTheme: () => null,
      gateIsOpen: false,
      post: null,
    }
    this.onGateClose = this.onGateClose.bind(this)
  }

  componentDidMount() {
    const { theme } = this.props.feedProps.settings
    this.loadTheme(theme)
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevState.gateIsOpen && this.state.gateIsOpen) {
      this.loadGate()
    }
  }

  onGateClose() {
    this.setState({
      gateIsOpen: false,
      post: null,
    })
  }

  onGateOpen(post) {
    this.setState({
      gateIsOpen: true,
      post,
    })
  }

  getAriaLabel() {
    const { locale } = this.props.feedProps.settings
    switch (this.state.theme) {
      case 'card-flow':
      case 'card-flow-auto':
      case 'card-flow-centered':
      case 'card-flow-auto-centered':
      case 'tile-grid':
      case 'tile-grid-auto':
        return locale.getTranslation('cardAndTileAriaLabel')
      case 'carousel':
      case 'carousel-auto':
        return locale.getTranslation('carouselAriaLabel')
      case 'single':
      case 'single-auto':
        return locale.getTranslation('singleAriaLabel')
      default:
        return null
    }
  }

  loadTheme(theme) {
    if (!theme) {
      return
    }

    importTheme(theme).then((module) => {
      const chosenTheme = module.default

      if (chosenTheme !== undefined) {
        this.setState({
          chosenTheme,
          theme,
        })
      }
    })
  }

  loadGate() {
    importTheme('gate').then((module) => {
      const gate = module.default

      if (gate !== undefined) {
        this.setState({
          gate,
        })
      }
    })
  }

  render() {
    const { gate: Gate, chosenTheme: ChosenTheme } = this.state
    const {
      feedProps: { feed, feedKey, settings, mediaUploaderSettings },
    } = this.props
    const { enable_media_uploader: isMediaUploader } = mediaUploaderSettings

    return (
      <div aria-label={this.getAriaLabel()} className="bzfy-o-context" role="article">
        <CustomCss settings={settings} />
        <GateContext.Provider
          value={{
            gateIsOpen: this.state.gateIsOpen,
            openGate: (post) => {
              const { posts } = feed
              const postKey = post.key
              const postIndex = posts.findIndex((p) => p.key === postKey)
              const { allowCookies, enableWebAnalytics } = settings
              sendTrackingEvent({
                action: 'click',
                allowCookies,
                enableWebAnalytics,
                eventName: 'post_clicked',
                eventLabel: 'post',
                eventAction: 'clicked',
                interactive: true,
                flowKey: feedKey,
                postId: postKey,
                target: 'flow',
                postPosition: postIndex,
              })
              this.setState({
                gateIsOpen: true,
                post,
              })
            },
            onGateClose: this.onGateClose,
          }}
        >
          {isMediaUploader && (
            <div className="bzfy-o-feed-button-media-uploader">
              <MediaUploader
                feedKey={feedKey}
                locale={settings.locale}
                mediaUploaderSettings={mediaUploaderSettings}
              />
            </div>
          )}
          <ChosenTheme {...this.props.feedProps} />
        </GateContext.Provider>
        {this.state.gateIsOpen ? (
          <Gate
            feedKey={this.props.feedProps.feedKey}
            onClose={this.onGateClose}
            post={this.state.post}
            posts={this.props.feedProps.feed.posts}
            settings={this.props.feedProps.settings}
          />
        ) : null}
      </div>
    )
  }
}
